import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import './newsSnippet.css'

const NewsSnippet = ({
  linkDestination, image, imgAlt, title, shortDescription,
}) => (
  <div className="snippet__grid">
    {
      image && (
        <Link to={linkDestination}>
          <Img className="snippet__grid--thumbnail" fluid={image.childImageSharp.fluid} alt={imgAlt} />
        </Link>
      )
    }
    <div className="snippet__grid-right">
      <h2>
        <Link className="snippet__grid-right--title" to={linkDestination}>{title}</Link>
      </h2>
      <p className="snippet__grid-right--short">{shortDescription}</p>
      <Link className="snippet__grid-right--button" to={linkDestination}>
      Czytaj dalej →
      </Link>
    </div>
  </div>
)

NewsSnippet.propTypes = {
  linkDestination: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
}

NewsSnippet.defaultProps = {
  linkDestination: '',
  imgAlt: '',
  title: '',
  shortDescription: '',
}

export default NewsSnippet
